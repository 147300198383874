import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, BoxProps } from "@mui/material";

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 200 200"
        enable-background="new 0 0 200 200"
      >
        <path
          fill="#D70156"
          opacity="1.000000"
          stroke="none"
          d="
M201.000000,34.000000 
	C201.000000,78.687561 201.000000,123.375122 200.663651,168.658661 
	C195.447784,185.446533 185.281235,196.210831 168.544876,200.255417 
	C168.316605,200.310577 168.179611,200.743423 168.000000,201.000000 
	C123.312439,201.000000 78.624878,201.000000 33.341957,200.663437 
	C17.115549,195.643921 6.470657,185.935638 2.042629,169.925873 
	C1.938641,169.549896 1.358202,169.305679 0.999998,169.000000 
	C1.000000,123.979103 1.000000,78.958214 1.336346,33.341354 
	C6.552799,16.553970 16.716993,5.787158 33.455418,1.744641 
	C33.683582,1.689536 33.820496,1.256592 34.000000,1.000000 
	C78.354225,1.000000 122.708458,1.000000 167.678192,1.334596 
	C184.808197,5.831896 195.549789,15.917986 199.988113,32.504299 
	C200.135162,33.053776 200.654312,33.503681 201.000000,34.000000 
M64.703835,128.420288 
	C64.062309,138.345673 63.261391,148.264191 62.834656,158.198807 
	C62.544685,164.949509 62.779259,171.722733 62.779259,178.427429 
	C77.952408,178.427429 92.535759,179.676727 106.818077,178.135803 
	C128.457458,175.801102 142.790497,154.554550 138.609924,133.453705 
	C135.183594,116.159668 121.382561,103.921883 103.499359,102.320114 
	C87.161438,100.856743 71.776871,110.972946 64.703835,128.420288 
M62.779270,77.460754 
	C62.779270,80.596916 62.779270,83.733086 62.779270,87.173149 
	C74.987778,87.173149 86.798241,87.852455 98.499184,87.010208 
	C115.079582,85.816742 127.618279,70.576546 126.732559,54.041245 
	C125.797226,36.579670 111.489662,23.093054 94.244980,23.417759 
	C77.199051,23.738718 63.400204,37.100021 62.868607,54.018703 
	C62.633656,61.496277 62.796806,68.986359 62.779270,77.460754 
z"
        />
        <path
          fill="#FEFAFC"
          opacity="1.000000"
          stroke="none"
          d="
M201.000000,33.531342 
	C200.654312,33.503681 200.135162,33.053776 199.988113,32.504299 
	C195.549789,15.917986 184.808197,5.831896 168.146851,1.334596 
	C178.926559,1.000000 189.853119,1.000000 201.000000,1.000000 
	C201.000000,11.686553 201.000000,22.374619 201.000000,33.531342 
z"
        />
        <path
          fill="#FEFAFC"
          opacity="1.000000"
          stroke="none"
          d="
M33.531342,1.000000 
	C33.820496,1.256592 33.683582,1.689536 33.455418,1.744641 
	C16.716993,5.787158 6.552799,16.553970 1.336346,32.872696 
	C1.000000,22.405655 1.000000,11.811309 1.000000,1.000000 
	C11.686584,1.000000 22.374634,1.000000 33.531342,1.000000 
z"
        />
        <path
          fill="#FEFAFC"
          opacity="1.000000"
          stroke="none"
          d="
M168.468658,201.000000 
	C168.179611,200.743423 168.316605,200.310577 168.544876,200.255417 
	C185.281235,196.210831 195.447784,185.446533 200.663651,169.127319 
	C201.000000,179.594345 201.000000,190.188690 201.000000,201.000000 
	C190.313416,201.000000 179.625366,201.000000 168.468658,201.000000 
z"
        />
        <path
          fill="#FEFAFC"
          opacity="1.000000"
          stroke="none"
          d="
M0.999998,169.468658 
	C1.358202,169.305679 1.938641,169.549896 2.042629,169.925873 
	C6.470657,185.935638 17.115549,195.643921 32.873299,200.663437 
	C22.406794,201.000000 11.813587,201.000000 1.000000,201.000000 
	C1.000000,190.646820 1.000000,180.292068 0.999998,169.468658 
z"
        />
        <path
          fill="#CEDD01"
          opacity="1.000000"
          stroke="none"
          d="
M64.829987,128.042343 
	C71.776871,110.972946 87.161438,100.856743 103.499359,102.320114 
	C121.382561,103.921883 135.183594,116.159668 138.609924,133.453705 
	C142.790497,154.554550 128.457458,175.801102 106.818077,178.135803 
	C92.535759,179.676727 77.952408,178.427429 62.779259,178.427429 
	C62.779259,171.722733 62.544685,164.949509 62.834656,158.198807 
	C63.261391,148.264191 64.062309,138.345673 64.829987,128.042343 
z"
        />
        <path
          fill="#CEDD01"
          opacity="1.000000"
          stroke="none"
          d="
M62.779251,76.965759 
	C62.796806,68.986359 62.633656,61.496277 62.868607,54.018703 
	C63.400204,37.100021 77.199051,23.738718 94.244980,23.417759 
	C111.489662,23.093054 125.797226,36.579670 126.732559,54.041245 
	C127.618279,70.576546 115.079582,85.816742 98.499184,87.010208 
	C86.798241,87.852455 74.987778,87.173149 62.779270,87.173149 
	C62.779270,83.733086 62.779270,80.596916 62.779251,76.965759 
z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
