import * as Yup from "yup";
import { Button, Stack, InputAdornment, IconButton } from '@mui/material';
import Iconify from "../../../components/Iconify";
import { FormProvider } from '../../../components/hook-form';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import { useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA_CHANGE_PASSWORD } from "../../../constants/schemas/schema_change_password";
import { useSearchParams } from "react-router-dom"
import { Password } from "../../../@types/password";

type Props = {
    saveInfo: (arr: any) => void;
}
export default function CreatePasswordForm({ saveInfo }: Props) {
    const [searchParams] = useSearchParams();
    const Schema = Yup.object().shape(SCHEMA_CHANGE_PASSWORD)
    const [stateForm] = useState<Password | any>({});
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    
    const defaultValue = useMemo(
        () => ({
            email: searchParams?.get('email') || "",
            oldPassword: stateForm?.oldPassword || "",
            newPassword: stateForm?.newPassword || "",
            confirmNewPassword: stateForm?.confirmNewPassword || "",
        }),
        [stateForm]
    )

    const method = useForm<Password>({
        resolver: yupResolver(Schema),
        defaultValues: defaultValue,
    })

    const {
        handleSubmit,
    } = method;

    return (
        <>
            <FormProvider methods={method} onSubmit={handleSubmit(saveInfo)}>
                <Stack direction="column" spacing={2} alignItems='center'>
                    <RHFTextField
                        name="oldPassword"
                        label="Contraseña antigua"
                        type={showOldPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowOldPassword(!showOldPassword)
                                        }
                                        edge="end"
                                    >
                                        <Iconify
                                            icon={
                                                showOldPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    ></RHFTextField>
                    <RHFTextField
                        name="newPassword"
                        label="Contraseña nueva"
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowNewPassword(!showNewPassword)
                                        }
                                        edge="end"
                                    >
                                        <Iconify
                                            icon={
                                                showNewPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    ></RHFTextField>
                    <RHFTextField
                        name="confirmNewPassword"
                        label="Confirmación de la contraseña"
                        type={showConfirmNewPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirmNewPassword(!showConfirmNewPassword)
                                        }
                                        edge="end"
                                    >
                                        <Iconify
                                            icon={
                                                showConfirmNewPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    ></RHFTextField>
                    <Button type="submit" variant="contained">
                        Confirmar
                    </Button>
                </Stack>
            </FormProvider>
        </>
    )

}