import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { LoginForm } from '../../sections/auth/login';
import { SnackAlert } from '../../components/Alert';
import { LoadingScreen } from '../../components/LoadingScreem';
import { useSelector } from '../../redux/store';
import { UtilsState } from '../../@types/utils_state';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export default function Login() {
  const { loading } = useSelector<UtilsState>((state) => state.utils);


  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm" style={{ textAlign: 'center' }}>
          <ContentStyle>
            <Stack direction="column">
              <Box sx={{
                padding: 5,
                width: 600,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: 2,
                boxShadow: 24,
              }}>
                <Typography variant="h4" gutterBottom>
                  BIENVENIDO A BOU COMPANY
                </Typography>
                <Logo disabledLink={true} sx={{
                  width: 70,
                  height: 70,
                  textAlign: 'center',
                  alignContent: 'center',
                  margin: 'auto',
                }} />
                <Stack spacing={1} sx={{ mt: 5 }}>
                  <LoginForm />
                </Stack>
              </Box>
            </Stack>
          </ContentStyle>
        </Container>
        <SnackAlert />
        {loading ? <LoadingScreen /> : null}
      </RootStyle>
    </Page>
  );
}
