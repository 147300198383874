import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert, IconButton, InputAdornment, Button, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import ModalLogin from "./ModalLogin";

const style = {
	bgcolor: 'background.paper',
	border: '2px solid #000',
	p: 4,
	borderRadius: 2,
};

type FormValuesProps = {
	email: string;
	password: string;
	afterSubmit?: string;
};

export default function LoginForm() {
	const { login } = useAuth();

	const isMountedRef = useIsMountedRef();

	const [showPassword, setShowPassword] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Ingrese una dirección de correo válida")
			.required("Campo requerido"),
		password: Yup.string().required("Campo requerido"),
	});

	const defaultValues = {
		email: "",
		password: "",
	};

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		reset,
		setError,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = methods;

	const onSubmit = async (data: FormValuesProps) => {
		try {
			await login(data.email, data.password);
		} catch (error) {
			console.error(error);

			reset();

			if (error instanceof Error) {
				if (isMountedRef.current) {
					setError("afterSubmit", {
						...error,
						message: error.message,
					});
				}
			}
		}
	};

	useEffect(() => {
		if (open) {
			handleOpen();
		}
		setOpen(false);
	}, [open]);

	return (
		<Stack>
			<Box sx={style}>
				<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
					<Stack spacing={3}>
						{!!errors.afterSubmit && (
							<Alert severity="error">{errors.afterSubmit.message}</Alert>
						)}
						<Typography variant="h5" sx={{ textAlign: "center" }}>
							Iniciar Sesión
						</Typography>
						<RHFTextField name="email" label="Email" />

						<RHFTextField
							name="password"
							label="Contraseña"
							type={showPassword ? "text" : "password"}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() =>
												setShowPassword(!showPassword)
											}
											edge="end"
										>
											<Iconify
												icon={
													showPassword
														? "eva:eye-fill"
														: "eva:eye-off-fill"
												}
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{ my: 2 }}
					>
					</Stack>
					<Stack spacing={1}>
						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							loading={isSubmitting}
						>
							Iniciar Sesión
						</LoadingButton>
						<Button onClick={() => handleOpen()} sx={{ fontSize: 12 }}>
							¿Olvidaste tu contraseña?
						</Button>

						<Button
							variant="contained"
							color="inherit"
						>
							Iniciar sesión con Google
						</Button>

						<ModalLogin modal={open} />
					</Stack>
				</FormProvider>
			</Box>
		</Stack>
	);
}