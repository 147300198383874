import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// types store
import { UtilsState } from '../@types/utils_state';

import utilsReducer from "./slices/utils";

// config persist
export const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

// slices
export type StoreState = {
    utils: UtilsState;
}

// -------------------------------------------------------------------
const rootReducer = combineReducers<StoreState>({
    utils: utilsReducer,
});

export default rootReducer;
