import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreState } from "../redux/rootReducer";
import { AlertState } from "../@types/utils_state";
import { setAlert } from "../redux/slices/utils";

enum E {
    E000 = "E000",
    E001 = "E001",
    E002 = "E002",
    E003 = "E003",
    E100 = "E100",
}

type Error<T extends string = E> = {
    [K in T]: AlertState;
};

export const Errors: Error<E> = {
    E000: { open: false } as AlertState,
    E001: {
        open: true,
        message: "Código de verificación incorrecto",
        type: "error"
    },
    E002: {
        open: true,
        message: "Contraseña actualizada correctamente",
        type: "success"
    },
    E100: {
        type: "error",
        message: "Ha ocurrido un error al intentar la acción",
        open: true,
    },
    E003: {
        open: true,
        message: "No se pudo cambiar la contraseña",
        type: "error"
    },
};


export const SnackAlert = () => {
    const error = useSelector<StoreState, AlertState>(
        (state) => state.utils.error
    );

    const handleCloseSnack = () => {
        setAlert(Errors.E000);
    };
    return (
        <Snackbar
            open={error.open}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            anchorOrigin={{
                horizontal: error.type == "success" ? "center" : "right",
                vertical: "top",
            }}
        >
            <Alert
                onClose={handleCloseSnack}
                severity={error.type}
                sx={{ width: "100%" }}
            >
                {error.message}
            </Alert>
        </Snackbar>
    );
};
