import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Button, Modal, Box, Typography, Stack, TextField, InputAdornment, IconButton } from "@mui/material";
import Logo from "../../../components/Logo";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Errors } from "../../../components/Alert";
import { closeLoading, openLoading, setAlert } from "../../../redux/slices/utils";
import Iconify from "../../../components/Iconify";


const URL = `${process.env.REACT_APP_HOST_API_KEY}/authorizer/v1`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};
interface Props {
    modal: boolean;
}
export default function ModalLogin({ modal = false }: Props) {
    //Modal 1
    const [open, setOpen] = useState(modal);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //Modal 2
    const [openM, setOpenM] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleOpenM = () => setOpenM(true);
    const handleCloseM = () => {
        setOpenM(false)
        setEmail("");
        setCode("");
        setPassword("");
        setPasswordConfirm("");
        setShowPassword(false);
        setShowPasswordConfirm(false);
    };
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string>("");

    const [color, setColor] = useState<string>("GrayText");

    const [code, setCode] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");

    let schema = Yup.object().shape({
        email: Yup.string()
            .email("Ingrese una dirección de correo válida")
            .required("Campo requerido"),
    });

    const validateEmail = (email: string) => {
        schema.validate({ email }).
            then(() => {
                openLoading();
                const aux = submitEmail();
                aux.then((res) => {
                    if (res === 200) {
                        closeLoading();
                        handleOpenM();
                        handleClose();
                    }
                })
            })
            .catch((err) => {
                setError(err.errors[0]);
            });
    }

    const submitEmail = async () => {
        try {
            const data = {
                Username: email,
            }
            const response = await axios.post(`${URL}/forgot-password`, data);
            return response.status;
        } catch (error: any) {
            return error.status;
        }
    }

    const submitCode = async () => {
        try {
            if (password !== passwordConfirm || password.length < 8) {
                return;
            }
            const data = {
                Username: email,
                ConfirmationCode: code,
                Password: password,
            }
            openLoading();
            await axios.post(`${URL}/confirm-forgot-password`, data);
            handleCloseM();
            setCode("");
            setAlert(Errors.E002);
            closeLoading();
        } catch (error: any) {
            setAlert(Errors.E001);
            closeLoading();
        }
    }

    const handleChange = (newValue: string) => {
        setCode(newValue)
    }

    useEffect(() => {
        if (modal) {
            handleOpen();
        }
    }, [modal]);

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    setEmail("");
                    setError("");
                    setColor("GrayText");
                    modal = false;
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Typography id="modal-modal-title" variant="h6">
                            BIENVENIDO A BOU COMPANY
                        </Typography>
                        <Logo disabledLink={true} sx={{
                            width: 75,
                            height: 75,
                        }} />
                        <Box sx={{
                            width: 400,
                            height: 330,
                            padding: 2,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            borderRadius: 2,
                        }}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                            >
                                <Typography id="modal-modal-title" sx={{ paddingTop: 2 }}>
                                    RECUPERAR CONTRASEÑA
                                </Typography>
                                <Typography id="modal-modal-description" variant="body2" sx={{ textAlign: 'justify' }}>
                                    Introduce tu correo electrónico para restablecer tu cuenta.
                                </Typography>
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setColor("contained")
                                    }}
                                    autoComplete="off"
                                    error={error ? true : false}
                                    helperText={error ? error : ""}
                                    sx={{ width: "100%" }}
                                />
                                <Button variant="contained" sx={{ backgroundColor: color }} onClick={() => {
                                    validateEmail(email);
                                }}>Enviar</Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openM}
                onClose={() => {
                    handleCloseM();
                    handleClose();
                    setEmail("");
                    setError("");
                    setColor("GrayText");
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Stack
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography id="modal-modal-title" variant="h6" justifyContent="center">
                            BIENVENIDO A BOU COMPANY
                        </Typography>
                        <Logo disabledLink={true} sx={{
                            width: 75,
                            height: 75,
                        }} />

                        <Stack
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography id="modal-modal-title" sx={{ paddingTop: 2 }} justifyContent="center">
                                RECUPERAR CONTRASEÑA
                            </Typography>

                        </Stack>
                        <Stack spacing={2} direction="row" width={"100%"}>
                            <Typography id="modal-modal-description" variant="body2" >
                                Ingresa tu código de verificación
                            </Typography>

                        </Stack>
                        <Stack spacing={3} direction="row">
                            <MuiOtpInput
                                value={code}
                                onChange={handleChange}
                                length={6}
                                TextFieldsProps={{ placeholder: '-' }}
                                sx={{ ".MuiOtpInput-TextField": { height: "50px" }, }}
                                display="flex"
                                gap={3}
                                height="50px"
                            />
                        </Stack>
                        <Stack spacing={2} direction="column" width={"100%"} sx={{ paddingTop: "15px" }}>
                            <TextField
                                name="password"
                                label="Contraseña"
                                type={showPassword ? "text" : "password"}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassword(!showPassword)
                                                }
                                                edge="end"
                                            >
                                                <Iconify
                                                    icon={
                                                        showPassword
                                                            ? "eva:eye-fill"
                                                            : "eva:eye-off-fill"
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={password.length < 8 && password.length != 0 ? true : false}
                                helperText={password.length < 8 ? "La contraseña debe tener al menos 8 caracteres" : ""}
                                onChange={(e) => {
                                    setPassword(e.target.value.toString());
                                    setColor("contained")
                                }}
                            />
                            <TextField
                                name="passwordConfirm"
                                label="Confirmar contraseña"
                                type={showPasswordConfirm ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPasswordConfirm(!showPasswordConfirm)
                                                }
                                                edge="end"
                                            >
                                                <Iconify
                                                    icon={
                                                        showPasswordConfirm
                                                            ? "eva:eye-fill"
                                                            : "eva:eye-off-fill"
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={password != passwordConfirm && passwordConfirm.length != 0 ? true : false}
                                helperText={password != passwordConfirm && passwordConfirm.length != 0 ? "Las contraseñas no coinciden" : ""}
                                onChange={(e) => {
                                    setPasswordConfirm(e.target.value.toString());
                                    setColor("contained")
                                }}
                            />
                            <Button variant="contained" sx={{ backgroundColor: color }} onClick={submitCode}>
                                Enviar
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}