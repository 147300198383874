import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AwsCognitoContext";
import GuestGuard from "./guards/GuestGuard";
import { store } from "./redux/store";
import Login from "./containers/auth/Login";
import CreateNewPassword from './sections/auth/password/CreateNewPassword';

const Root = () => {
	return (
		<React.StrictMode>
			<ReduxProvider store={store}>
				<AuthProvider>
					<HelmetProvider>
						<BrowserRouter>
							<Routes>
								<Route
									path="/auth"
									element={
										<GuestGuard>
											<Login />
										</GuestGuard>
									}
								/>
								<Route
									path="/auth-change-password"
									element={
										<GuestGuard>
											<CreateNewPassword />
										</GuestGuard>
									}
								/>
							</Routes>
						</BrowserRouter>
					</HelmetProvider>
				</AuthProvider>
			</ReduxProvider>
		</React.StrictMode>
	);
};

export default Root;
