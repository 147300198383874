
import * as Yup from "yup";
import YupPassword from 'yup-password'
YupPassword(Yup)

export const SCHEMA_CHANGE_PASSWORD = {
    email: Yup.string().required("El email es requerido"),
    oldPassword: Yup.string().required("El campo es requerido").
    min(8, 'La contraseña debe contener al menos 8 caracteres'),
    newPassword: Yup.string().required("El campo es requerido").
    min(8, 'La contraseña debe contener al menos 8 caracteres').
    minUppercase(1,'La contraseña debe contener al menos 1 letra mayúscula').
    minNumbers(1, 'La contraseña debe contener al menos 1 número').
    minSymbols(1, 'La contraseña debe contener al menos 1 símbolo').
    minLowercase(1,'La contraseña debe contener al menos 1 letra minuscula'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Tus contraseñas no coinciden.'),
}