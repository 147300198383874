import { createSlice } from "@reduxjs/toolkit";
import { AlertState, UtilsState } from "../../@types/utils_state"
import { dispatch } from "../store";

const initialState: UtilsState = {
    loading: false,
    error: {
        open: false,
        message: "",
        type: "info",
    }
}

const slices = createSlice({
    name: "utils",
    initialState: initialState,
    reducers: {
        setLoading(state, actions) {
            state.loading = actions.payload;
        },
        setError(state, action) {
            state.error = action.payload
        },
    }
});

export default slices.reducer;

export function openLoading() {
    dispatch(slices.actions.setLoading(true));
}

export function closeLoading() {
    dispatch(slices.actions.setLoading(false));
}
export function setAlert(error: AlertState) {
    dispatch(slices.actions.setError(error))
}