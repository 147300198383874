
import { Grid, Typography, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Password } from "../../../@types/password";
import { useNavigate, useSearchParams } from "react-router-dom"
import { dispatch } from "../../../redux/store";
import { changePassword, validateChangePassword } from "../../../redux/slices/auth";
import Logo from "../../../components/Logo";
import CreatePasswordForm from './CreatePasswordForm';
import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { openLoading, closeLoading } from '../../../redux/slices/utils';


export default function CreateNewPassword() {
    openLoading();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [validatePass, setValidatePass] = useState(false)

    const saveInfo = async (data: Password) => {
        await dispatch(changePassword(data))
            .then(() => {
                navigate('/auth');
            })
            .catch(() => {
                navigate('/auth');
            });
    }
    const validatePassword = async () => {

        const email = searchParams?.get('email') || "";
        await dispatch(validateChangePassword(email))
            .then(() => {
                setValidatePass(true);
            })
            .catch((error: AxiosError) => {
                if (error.code === 'AUT015') {
                    setValidatePass(false);
                    navigate('/auth');
                }
            });
        closeLoading()
    }

    useEffect(() => {
        validatePassword();
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80vh' }}>
                <Grid alignItems={'center'} container direction='column' justifyContent='center'>
                    <Card sx={{ minWidth: '20%' }}>
                        <CardHeader
                            title={
                                <Typography variant='h3' align="center">
                                    BIENVENIDO A BOU COMPANY
                                </Typography>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <Logo disabledLink={true} sx={{
                                width: 70,
                                height: 70,
                                textAlign: 'center',
                                alignContent: 'center',
                                margin: 'auto',
                            }} />
                            <Box sx={{ marginX: '14px', padding: '42px' }} className='card'>
                                <Typography variant='h6' align="left" mb={3}>
                                    CAMBIAR CONTRASEÑA
                                </Typography>
                                {validatePass === true && <CreatePasswordForm saveInfo={saveInfo}></CreatePasswordForm>}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
        </>
    );
}