import { AUTHORIZER_PATH } from "../../constants/paths/paths";
import axios from "../../utils/axios";
import { Password } from '../../@types/password';

const path = AUTHORIZER_PATH;

export function changePassword(data: Password) {
    return async () => {
        await axios.patch(`${path}/change-password`, { email: data.email, oldPassword: data.oldPassword, newPassword: data.newPassword });
    }
}

export function validateChangePassword(email: string) {
    return async () => {
        await axios.post(`${path}/validate-change-password`, { email: email });
    }
}
